import {
  Card,
  CardContent,
  Grid,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Divider,
  Box,
  CircularProgress,
} from "@mui/material";
import {
  GrantVestmentRequestDetailPageUrl,
  GrantVestmentTransferDetailPageUrl,
} from "constants/pages";
import { useGetGrantVestmentRequestsQuery } from "hooks/use-services/grant-vestment.service";
import useIntersectionObserver from "hooks/use-services/useIntersectionObserver";

import { AuthContext } from "providers/userProvider";
import { FC, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { warm, neutral, errorAlert, primary } from "style/color";
import { AuthData } from "types/auth";

import {
  GRANT_VESTMENT_REQUEST_STATUS_ENUM,
  GRANT_VESTMENT_REQUEST_TYPE_ENUM,
  GrantVestmentRequest,
} from "types/grant-vestment";
import { shortFormat } from "utils/date-format";
import { generateUrl } from "utils/generateUrl";
import { moneyFormat } from "utils/money-format";

interface GrantVestmentRequestsListProps {
  grantvestmentId: number;
}

const GrantVestmentRequestsList: FC<GrantVestmentRequestsListProps> = ({
  grantvestmentId,
}) => {
  const { selectedAccount } = useContext<AuthData>(AuthContext);

  const {
    data: paginatedData,
    isFetching,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetGrantVestmentRequestsQuery(grantvestmentId, {
    limit: 6,
    filter: {
      fundAccountId: selectedAccount?.fundAccountId!,
    },
  });
  const navigate = useNavigate();

  const requestsList = useMemo<GrantVestmentRequest[]>(
    () =>
      paginatedData ? paginatedData?.pages?.flatMap((item) => item.data) : [],
    [paginatedData]
  );
  const lastElementRef = useIntersectionObserver({
    isFetching,
    isLoading,
    fetchNextPage,
    hasNextPage,
  });
  console.log(requestsList);

  return isLoading ? (
    <Grid item xs={12} sm={10} textAlign={"center"} sx={{ m: 3 }}>
      <CircularProgress color="primary" />
    </Grid>
  ) : (
    <Card sx={{ boxShadow: "none", p: 4 }}>
      <CardContent>
        {requestsList.length !== 0 ? (
          <>
            {requestsList.map(
              (movement: GrantVestmentRequest, index: number) => {
                const {
                  grantVestmentRequestId,
                  status,
                  user,
                  type,
                  appliedAmount,
                  createdAt,
                } = movement;
                return (
                  <List
                    className="successor"
                    sx={{
                      width: "100%",
                      bgcolor: "background.paper",
                      mt: 2,
                      [` & .MuiListItem-root:hover`]: {
                        backgroundColor: warm[200],
                        cursor: "pointer",
                      },
                    }}
                  >
                    <ListItem
                      key={index}
                      onClick={() => {
                        if (
                          type ===
                            GRANT_VESTMENT_REQUEST_TYPE_ENUM.TRANSFER_RECEIVE ||
                          type ===
                            GRANT_VESTMENT_REQUEST_TYPE_ENUM.TRANSFER_SEND
                        ) {
                          const url = generateUrl(
                            GrantVestmentTransferDetailPageUrl,
                            {
                              action:
                                type ===
                                GRANT_VESTMENT_REQUEST_TYPE_ENUM.TRANSFER_RECEIVE
                                  ? "receive"
                                  : "send",
                              type: "GRANTVESTMENT",
                              id: grantVestmentRequestId,
                            }
                          );
                          navigate(url);
                        } else {
                          navigate(
                            GrantVestmentRequestDetailPageUrl.replace(
                              ":id",
                              grantVestmentRequestId.toString()
                            )
                          );
                        }
                      }}
                    >
                      <Grid alignItems="center" container>
                        <Grid item xs={12} sm={3} textAlign={"start"}>
                          <ListItemText
                            sx={{
                              display: "inline-grid",
                              width: "auto",
                              flex: "none",
                            }}
                            secondary={
                              <Typography
                                sx={{ color: neutral[600] }}
                                variant="smallCopyBold"
                              >{`By ${user.firstName} ${user.lastName}`}</Typography>
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={2} textAlign={"center"}>
                          <Typography
                            variant="bodyCopyRegular"
                            sx={{ color: neutral[600] }}
                          >
                            {shortFormat(createdAt)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3} textAlign={"center"}>
                          <Typography
                            variant="bodyCopyRegular"
                            sx={{
                              color: neutral[900],
                              textTransform: "capitalize",
                            }}
                          >
                            {type.toLowerCase()}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={2} textAlign={"center"}>
                          <Button
                            sx={{
                              borderRadius: "4px",
                              p: 1,
                              backgroundColor: warm[200],
                              color: ["IN PROGRESS", "PENDING"].includes(status)
                                ? neutral[500]
                                : status ===
                                    GRANT_VESTMENT_REQUEST_STATUS_ENUM.REJECTED ||
                                  status ===
                                    GRANT_VESTMENT_REQUEST_STATUS_ENUM.CANCELED
                                ? errorAlert[500]
                                : primary[500],
                              paddingX: 2,
                              paddingY: 1.5,
                              fontFamily: "Quattrocento Sans",
                              fontSize: "12px",
                              fontWeight: "700",
                              lineHeight: "13.3px",
                            }}
                          >
                            {status}
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={2} textAlign={"end"}>
                          <Typography
                            sx={{
                              px: 1,
                              py: 0.5,

                              color: !["IN PROGRESS", "PENDING"].includes(
                                status
                              )
                                ? neutral[900]
                                : neutral[500],
                              backgroundColor: "transparent",
                              textDecoration:
                                status ===
                                  GRANT_VESTMENT_REQUEST_STATUS_ENUM.REJECTED ||
                                status ===
                                  GRANT_VESTMENT_REQUEST_STATUS_ENUM.CANCELED
                                  ? "line-through"
                                  : "none",
                              borderRadius: 1,
                            }}
                            variant="bodyCopyBold"
                            gutterBottom
                          >
                            <>
                              {" "}
                              {type ===
                                GRANT_VESTMENT_REQUEST_TYPE_ENUM.TRANSFER_SEND &&
                                "-"}
                              $
                              {type !==
                              GRANT_VESTMENT_REQUEST_TYPE_ENUM.TRANSFER_SEND
                                ? moneyFormat(appliedAmount)
                                : moneyFormat(appliedAmount * -1)}
                            </>
                          </Typography>
                        </Grid>
                      </Grid>
                    </ListItem>
                    {index !== requestsList.length - 1 && (
                      <Divider key={index + 100} />
                    )}
                  </List>
                );
              }
            )}
            {
              <Box ref={lastElementRef}>
                {isFetchingNextPage && (
                  <Grid item xs={12} sm={10} textAlign={"center"} sx={{ m: 3 }}>
                    {" "}
                    <CircularProgress color="primary" />
                  </Grid>
                )}
              </Box>
            }
          </>
        ) : (
          <Typography
            sx={{ mt: 4, color: neutral[600] }}
            variant="bodyCopyRegular"
          >
            You don't have any past requests.
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default GrantVestmentRequestsList;
