import { Account } from "./account";
import { User } from "./user";
export enum ASSET_TYPE {
  CASH = "CASH",
  UNIT = "UNIT",
}
export enum TRANSFER_TRANSACTION_TYPE_ENUM {
  CASH = "CASH",
  INVESTMENTS = "INVESTMENTS",
  GRANTVESTMENT = "GRANTVESTMENT",
}
export enum TRANSACTION_STATUS {
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  PENDING = "PENDING",
  CANCELED = "CANCELED",
  PROCESSED = "PROCESSED",
}

export interface TransferTransaction {
  transferId: number;
  receiverFund: Account;
  senderFund: Account;
  transferType: TRANSFER_TRANSACTION_TYPE_ENUM;
  amount: number;
  assetType: ASSET_TYPE;
  ticker?: string;
  grantVestmentId?: number;
  user: User;
  reference: string;
  rejectionReason: string;
  executionDate: Date;
  status: TRANSACTION_STATUS;
}

export interface TransferTransactionInput {
  receiverFundId: number;
  transferType: TRANSFER_TRANSACTION_TYPE_ENUM;
  amount: number;
  assetType: ASSET_TYPE;
  ticker?: string;
  grantVestmentId?: number;
}
