import { Box, Button, Typography } from "@mui/material";
import Layout from "components/layout/layout";
import { FundDashboardPage } from "constants/pages";
import { useContext, useEffect, useRef, useState } from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateBefore";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CustomStepper } from "components/shared/custom-stepper";
import { InvestmentRequestSelectAsset } from "components/investment/investment-request/investment-request-select-asset";
import { InvestmentRequestEnterData } from "components/investment/investment-request/investment-request-enter-data";
import {
  INVESTMENT_REQUEST_INTEREST_ENUM,
  INVESTMENT_REQUEST_TYPE_ENUM,
} from "types/investment-request";
import { ThankYou4YourTransfer } from "components/shared/form-submit-thank-you-message";
import { AuthContext } from "providers/userProvider";
import { AuthData } from "types/auth";

const InvestmentTransferTransactionPage = () => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState<number>(0);
  const { selectedAccount } = useContext<AuthData>(AuthContext);

  const [ticker, setTicker] = useState<string>("");
  const [requestInfo, setRequestInfo] = useState<{
    name: string;
    amount: number;
    units: number;
    interestedIn: INVESTMENT_REQUEST_INTEREST_ENUM;
  }>({
    name: "",
    amount: 0,
    units: 0,
    interestedIn: INVESTMENT_REQUEST_INTEREST_ENUM.UNITS,
  });
  const [searchParams] = useSearchParams();
  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      if (searchParams && searchParams.get("ticker")) {
        setTicker(searchParams.get("ticker") || "");
        setActiveStep(1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);
  const handleSuccess = (
    name: string,
    amount: number,
    units: number,
    interestedIn: INVESTMENT_REQUEST_INTEREST_ENUM
  ) => {
    setRequestInfo({ name, amount, units, interestedIn });
    setActiveStep(2);
  };
  const selectAsset = (ticker: string): void => {
    setTicker(ticker);
    setActiveStep(1);
  };

  return (
    <Layout>
      {() => (
        <Box sx={{ m: 5 }}>
          <Button
            onClick={() => navigate(FundDashboardPage)}
            className="footer"
            variant="text"
            sx={{
              color: "neutral.main",
              "&:hover": {
                backgroundColor: "transparent",
                textDecoration: "underline",
              },
            }}
            startIcon={<NavigateNextIcon />}
          >
            <Typography variant="bodyCopyBold">
              Back to fund dashboard
            </Typography>
          </Button>
          <CustomStepper
            stepsTitles={[
              "Select asset",
              "Enter amount and confirm",
              "Confirmation",
            ]}
            activeStep={activeStep}
          />
          {activeStep === 0 && (
            <InvestmentRequestSelectAsset selectAsset={selectAsset} />
          )}
          {activeStep === 1 && ticker !== "" && (
            <InvestmentRequestEnterData
              ticker={ticker}
              handleSuccess={handleSuccess}
              type={INVESTMENT_REQUEST_TYPE_ENUM.TRANSFER}
            />
          )}
          {activeStep === 2 && requestInfo.name !== "" && (
            <ThankYou4YourTransfer
              amount={requestInfo.units}
              type={"INVESTMENT"}
              from={selectedAccount?.fundAccountName!}
              to={requestInfo.name}
              name={ticker}
            />
          )}
        </Box>
      )}
    </Layout>
  );
};

export default InvestmentTransferTransactionPage;
