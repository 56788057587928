import { addRecommendedCharityAPI } from "api/recommended-charity.api";
import { useMutation } from "react-query";
import { RecommendedCharityInput } from "types/recommended-charity";

const RECOMMENDED_CHARITY_RQ_KEY = "recommended-charity";

export const useAddRecommendedCharityMutation = () => {
  return useMutation<unknown, unknown, RecommendedCharityInput>({
    mutationKey: [RECOMMENDED_CHARITY_RQ_KEY],
    mutationFn: (data) => addRecommendedCharityAPI(data),
  });
};
