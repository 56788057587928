import { Box, Button, CircularProgress, Grid } from "@mui/material";
import { TransactionDetailsCard } from "components/transaction-details/transaction-details";
import { TransactionDetailsItem } from "components/transaction-details/transaction-details-item";
import { shortFormat } from "utils/date-format";
import { ReactComponent as TransferOutgoingIcon } from "./../../assets/Icons/transfer-outgoing.svg";
import Layout from "components/layout/layout";
import {
  useCancelTransferMutation,
  useGetTransfers,
} from "hooks/use-services/transfer-transaction.service";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "providers/userProvider";
import { AuthData } from "types/auth";
import { useNavigate, useParams } from "react-router-dom";
import { TRANSACTION_STATUS } from "types/transfer-transaction";
import { ReactComponent as ExtendedIcon } from "./../../assets/Icons/extended.svg";
import { BackToPreviousButton } from "components/shared/back-to-previous-button";
import CloseIcon from "@mui/icons-material/Close";
import { USER_ROLE } from "types/account";
import {
  MyCashPageUrl,
  MyGrantVestmentRequestPage,
  MyInvestmentsRequestsPage,
} from "constants/pages";

const getStatusCard = (status: TRANSACTION_STATUS) => {
  if (status === TRANSACTION_STATUS.PENDING) {
    return {
      icon: <ExtendedIcon />,
      title: "Pending",
      text: "Transactions appear as pending until transfer is approved.",
    };
  }
};

export const TransferTransactionDetailsPage = () => {
  const { type, id, action } = useParams();
  const { selectedAccount, userRole } = useContext<AuthData>(AuthContext);

  const { data, isLoading } = useGetTransfers({
    fundId: selectedAccount?.fundAccountId!,
    senderTransactionId:
      type?.toLocaleUpperCase() !== "CASH" && action && action === "send"
        ? parseInt(id!)
        : undefined,
    receiverTransactionId:
      type?.toLocaleUpperCase() !== "CASH" && action && action !== "send"
        ? parseInt(id!)
        : undefined,
    transferType: type?.toLocaleUpperCase(),
    transferId:
      type?.toLocaleUpperCase() === "CASH" ? parseInt(id!) : undefined,
  });
  const navigate = useNavigate();
  const [fundType, setFundType] = useState("");
  const transfer = data?.data[0];

  useEffect(() => {
    if (
      transfer &&
      transfer.senderFund.fundAccountId === selectedAccount?.fundAccountId
    ) {
      setFundType("SENDER");
    } else {
      setFundType("RECEIVER");
    }
  }, [selectedAccount?.fundAccountId, transfer]);
  const { mutate: cancelTransfer, isLoading: loadingCancel } =
    useCancelTransferMutation(transfer?.transferId!);

  const cancel = (id: number) => {
    cancelTransfer(id, {
      onSuccess: () => {
        switch (type) {
          case "INVESTMENTS":
            navigate(MyInvestmentsRequestsPage);
            break;
          case "GRANTVESTMENT":
            navigate(MyGrantVestmentRequestPage);
            break;
          case "CASH":
            navigate(MyCashPageUrl);
            break;
          default:
            console.error("no type");
        }
      },
    });
  };
  return (
    <Layout>
      {() =>
        isLoading ? (
          <Box sx={{ display: "flex", justifyContent: "center", my: 5 }}>
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <>
            <Box sx={{ my: 4, width: "100%" }}>
              <>
                <Grid sx={{ m: 10 }} container spacing={2}>
                  <Grid item xs={12} sm={9}>
                    <BackToPreviousButton />
                  </Grid>
                  {transfer && (
                    <Grid item xs={12} sm={9}>
                      <TransactionDetailsCard
                        amount={
                          fundType === "RECEIVER"
                            ? transfer?.amount
                            : -transfer?.amount
                        }
                        type={`${type} TRANSFER`}
                        title={transfer.reference}
                        avatar={<TransferOutgoingIcon />}
                        status={transfer?.status}
                        statusCard={getStatusCard(transfer?.status)}
                      >
                        <TransactionDetailsItem
                          label="Type"
                          value={
                            fundType === "RECEIVER"
                              ? `Transfer Receive`
                              : `Transfer Send`
                          }
                        />
                        <TransactionDetailsItem
                          label="DATE"
                          value={shortFormat(transfer?.executionDate)!}
                        />
                        <TransactionDetailsItem
                          label="INITIATED BY"
                          value={`${transfer.user.firstName} ${transfer.user.lastName}`}
                        />

                        <TransactionDetailsItem
                          label="Fund"
                          value={
                            fundType === "RECEIVER"
                              ? transfer?.senderFund?.fundAccountName
                              : transfer?.receiverFund?.fundAccountName
                          }
                        />
                      </TransactionDetailsCard>
                    </Grid>
                  )}
                  {transfer?.status === TRANSACTION_STATUS.PENDING &&
                    action === "send" && (
                      <Grid item xs={12} sm={2}>
                        <Button
                          fullWidth
                          variant="contained"
                          sx={{ m: 1 }}
                          size="large"
                          startIcon={<CloseIcon />}
                          disabled={
                            userRole !== USER_ROLE.ADMIN || loadingCancel
                          }
                          onClick={() => cancel(transfer?.transferId)}
                        >
                          Cancel
                        </Button>
                      </Grid>
                    )}
                </Grid>
              </>
            </Box>
          </>
        )
      }
    </Layout>
  );
};
