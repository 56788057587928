import React, { useContext, useState } from "react";
import { Box, Button, Container, Grid, Link } from "@mui/material";
import Layout from "components/layout/layout";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "providers/userProvider";
import { AuthData } from "types/auth";

import { ReactComponent as ExtendedIcon } from "./../../assets/Icons/extended.svg";
import {
  useCancelContributionTransactionMutation,
  useGetContributionTransactionQuery,
} from "hooks/use-services/contribution-transaction.service";
import { ReactComponent as DonationIcon } from "./../../assets/Icons/Donation.svg";
import { ReactComponent as DownloadIcon } from "./../../assets/Icons/download.svg";
import { ReactComponent as EditIcon } from "./../../assets/Icons/edit.svg";

import CloseIcon from "@mui/icons-material/Close";
import { TransactionDetailsItem } from "components/transaction-details/transaction-details-item";
import { TransactionDetailsCard } from "components/transaction-details/transaction-details";
import { shortFormat } from "utils/date-format";
import {
  CashInformation,
  OtherAssetsInformation,
  PAYMENT_METHOD_ENUM,
  SecuritiesInformation,
  TRANSACTION_STATUS,
  TRANSACTION_TYPE_ENUM,
} from "types/contribution-transaction";
import {
  ContributionReceiptsPage,
  EditContributionReceiptsPage,
} from "constants/pages";
import { CancelContribution } from "components/contribution-transaction/contribution-recipits/cancel-contribution";
import { BackToPreviousButton } from "components/shared/back-to-previous-button";
import { USER_ROLE } from "types/account";
export const ContributionTransactionDetailsPage = () => {
  const { id } = useParams();
  const { selectedAccount, userRole } = useContext<AuthData>(AuthContext);
  const [openCancelDialog, setOpenCancelDialog] = useState<boolean>(false);
  const { data: contributionTransaction, isSuccess } =
    useGetContributionTransactionQuery(
      selectedAccount?.fundAccountId || 0,
      id ? +id : 0
    );
  const navigate = useNavigate();
  const { mutate: cancelContributionTransactionRequest } =
    useCancelContributionTransactionMutation(
      selectedAccount?.fundAccountId || 0
    );

  const getAmount = () => {
    if (contributionTransaction?.status === TRANSACTION_STATUS.PROCESSED) {
      return contributionTransaction.contributionReport.appliedAmount;
    } else {
      if (contributionTransaction?.type === TRANSACTION_TYPE_ENUM.CASH) {
        return (
          contributionTransaction.contributionInformation as CashInformation
        ).requestedAmount;
      }
      if (contributionTransaction?.type === TRANSACTION_TYPE_ENUM.SECURITIES) {
        return (
          contributionTransaction.contributionInformation as SecuritiesInformation
        ).estimatedAmount;
      }
      if (contributionTransaction?.type === TRANSACTION_TYPE_ENUM.OTHER) {
        return (
          contributionTransaction.contributionInformation as OtherAssetsInformation
        ).estimatedAmount;
      }
    }
    return 0;
  };
  const getQuantity = () => {
    if (contributionTransaction?.type === TRANSACTION_TYPE_ENUM.SECURITIES) {
      return `${(
          contributionTransaction.contributionInformation as SecuritiesInformation
        ).quantity
        } ${(
          contributionTransaction.contributionInformation as SecuritiesInformation
        ).ticker
        }`;
    } else {
      return undefined;
    }
  };
  const getTicker = () => {
    if (contributionTransaction?.type === TRANSACTION_TYPE_ENUM.SECURITIES) {
      return (
        contributionTransaction.contributionInformation as SecuritiesInformation
      ).securityName;
    } else {
      return undefined;
    }
  };
  const openEditPage = () => {
    const url = EditContributionReceiptsPage.replace(
      ":id",
      contributionTransaction!.contributionId.toString()
    );
    navigate(url);
  };
  const cancelContribution = (id: number) => {
    cancelContributionTransactionRequest(id, {
      onSuccess: () => {
        navigate(ContributionReceiptsPage);
      },
    });
  };
  const getStatusCard = () => {
    if (contributionTransaction?.status === TRANSACTION_STATUS.PENDING) {
      return {
        icon: <ExtendedIcon />,
        title: "Pending",
        text: "Contributions appear as pending until the payment is received.",
      };
    }
    return undefined;
  };
  return (
    <Layout>
      {() => (
        <>
          <Box sx={{ my: 4, width: "100%" }}>
            <React.Fragment>
              <Container>
                {isSuccess && contributionTransaction && (
                  <Grid sx={{ m: 5 }} container spacing={2}>
                    <Grid item xs={12} sm={9}>
                      <BackToPreviousButton />
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <TransactionDetailsCard
                        amount={getAmount() || 0}
                        quantity={getQuantity()}
                        type="Contribution"
                        title={contributionTransaction.contributionReport.name}
                        avatar={<DonationIcon />}
                        status={contributionTransaction.status}
                        statusCard={getStatusCard()}
                      >
                        <TransactionDetailsItem
                          label="Type"
                          value="Contribution"
                        />
                        <TransactionDetailsItem
                          label="Date"
                          value={
                            shortFormat(contributionTransaction.executionDate)!
                          }
                        />
                        <TransactionDetailsItem
                          label="Initiated by"
                          value={`${contributionTransaction.user.firstName} ${contributionTransaction.user.lastName}`}
                        />
                        {contributionTransaction.type ===
                          TRANSACTION_TYPE_ENUM.SECURITIES && (
                            <TransactionDetailsItem
                              label=""
                              value={getTicker()!}
                            />
                          )}
                        <TransactionDetailsItem
                          label="Fund"
                          value={contributionTransaction.fund.fundAccountName}
                        />
                      </TransactionDetailsCard>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      {!["PENDING", "REJECTED"].includes(
                        contributionTransaction.status
                      ) && (
                          <Link
                            href={contributionTransaction.receipt?.url}
                            target="__blank"
                            underline="none"
                            color="inherit"
                          >
                            <Button
                              disabled={
                                (contributionTransaction.type ===
                                  TRANSACTION_TYPE_ENUM.CASH &&
                                  (
                                    contributionTransaction.contributionInformation as CashInformation
                                  ).paymentMethod === PAYMENT_METHOD_ENUM.DAF) ||
                                !contributionTransaction.receipt
                              }
                              fullWidth
                              startIcon={<DownloadIcon />}
                              variant="contained"
                            >
                              Download
                            </Button>
                          </Link>
                        )}
                      {contributionTransaction.status ===
                        TRANSACTION_STATUS.PENDING && (
                          <>
                            <CancelContribution
                              open={openCancelDialog}
                              onClose={() => setOpenCancelDialog(false)}
                              contributionTransaction={contributionTransaction!}
                              cancelContribution={cancelContribution!}
                            />

                            <Button
                              disabled={userRole === USER_ROLE.VIEWER}
                              fullWidth
                              startIcon={<EditIcon />}
                              variant="contained"
                              onClick={openEditPage}
                            >
                              Edit
                            </Button>
                            <br></br>
                            <Button
                              disabled={userRole === USER_ROLE.VIEWER}
                              sx={{ mt: 1 }}
                              fullWidth
                              size="large"
                              startIcon={<CloseIcon />}
                              onClick={() => {
                                setOpenCancelDialog(true);
                              }}
                              variant="contained"
                            >
                              Delete
                            </Button>
                          </>
                        )}
                    </Grid>
                  </Grid>
                )}
              </Container>
            </React.Fragment>
          </Box>
        </>
      )}
    </Layout>
  );
};
