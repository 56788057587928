import apiHttpClient from "libs/api-http-client";
import { PaginatedEntityList } from "types/pagination";
import { TransferTransaction } from "types/transfer-transaction";
export interface GetTransferQuery {
  offset?: number;
  limit?: number;
  year?: number;
  status?: string;
  userId?: number;
  fundId: number;
  transferId?: number;
  senderTransactionId?: number;
  receiverTransactionId?: number;
  transferType?: string;
}

export const addTransferAPI = (data: {
  fundId: number;
  transfer: TransferTransaction;
}): Promise<unknown> =>
  apiHttpClient.post(
    `/funds/${data.fundId}/transfer-transactions/`,
    data.transfer
  );

export const getTransferAPI = (
  query: GetTransferQuery
): Promise<PaginatedEntityList<TransferTransaction>> =>
  apiHttpClient.get(`/transfer-transactions`, { params: query });

export const cancelTransferAPI = (
  transferId: number
): Promise<TransferTransaction> =>
  apiHttpClient.delete(`/transfer-transactions/${transferId}`);
