import {
  Box,
  Button,
  Divider,
  FormHelperText,
  Grid,
  Paper,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateBefore";
import Layout from "components/layout/layout";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "providers/userProvider";
import { AuthData } from "types/auth";
import { useContext, useState } from "react";
import { moneyFormat } from "utils/money-format";
import { neutral, warm } from "style/color";
import { NumericFormatCustom } from "components/shared/currency-input";
import { ReactComponent as ErrorIcon } from "./../../assets/Icons/error.svg";
import { ThankYou4YourTransfer } from "components/shared/form-submit-thank-you-message";
import { useAddTransferMutation } from "hooks/use-services/transfer-transaction.service";
import {
  ASSET_TYPE,
  TRANSFER_TRANSACTION_TYPE_ENUM,
} from "types/transfer-transaction";
import { SelectedFundDropDown } from "components/shared/select-fund-dropdown";

const CashTransferTransactionPage = () => {
  const [requestedAmount, setRequestedAmount] = useState<number>(0);
  const navigate = useNavigate();
  const { selectedAccount } = useContext<AuthData>(AuthContext);

  const [selectedFund, setSelectedFundAccount] = useState<{
    fundId: number;
    fundName: string;
  }>();

  const {
    mutate: makeTransfer,
    isLoading: isMakingTransferLoading,
    isSuccess: isMakingTransferSuccess,
  } = useAddTransferMutation(selectedAccount?.fundAccountId!);

  const onConfirmTransfer = () => {
    makeTransfer({
      receiverFundId: selectedFund?.fundId!,
      transferType: TRANSFER_TRANSACTION_TYPE_ENUM.CASH,
      amount: requestedAmount,
      assetType: ASSET_TYPE.CASH,
    });
  };
  const checkBalance = () => {
    if (selectedAccount?.availableBalance) {
      return (
        selectedAccount?.availableBalance === 0 ||
        requestedAmount > selectedAccount?.availableBalance!
      );
    }
  };

  return (
    <Layout>
      {() => (
        <Box sx={{ m: 5 }}>
          <Button
            onClick={() => navigate(-1)}
            className="footer"
            variant="text"
            sx={{
              color: "neutral.main",
              "&:hover": {
                backgroundColor: "transparent",
                textDecoration: "underline",
              },
            }}
            startIcon={<NavigateNextIcon />}
          >
            <Typography variant="bodyCopyBold">Back to My Cash</Typography>
          </Button>
          <TransferCashTransactionPageStepper
            activeStep={isMakingTransferSuccess ? 1 : 0}
          />
          {isMakingTransferSuccess ? (
            <Box sx={{ paddingTop: 10 }}>
              <ThankYou4YourTransfer
                amount={requestedAmount}
                type={"CASH"}
                from={selectedAccount?.fundAccountName!}
                to={selectedFund?.fundName!}
              />
            </Box>
          ) : (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <Paper
                    variant="outlined"
                    sx={{ paddingY: 6, paddingX: 4, border: "none" }}
                  >
                    <Stack spacing={3}>
                      <Grid sx={{ mt: 2 }} item xs={12} sm={4.9}>
                        <SelectedFundDropDown
                          setSelectedFund={setSelectedFundAccount}
                        />
                      </Grid>
                      <Box>
                        <Typography className="subtitle3" variant="subtitle3">
                          Transferred amount
                        </Typography>
                      </Box>
                      <Box>
                        <Grid container alignItems="center" spacing={2}>
                          <Grid item xs={11} sm={5}>
                            <TextField
                              fullWidth
                              size="small"
                              variant="outlined"
                              placeholder="Amount"
                              value={requestedAmount || ""}
                              onChange={(e) =>
                                setRequestedAmount(parseFloat(e.target.value))
                              }
                              InputProps={{
                                inputComponent: NumericFormatCustom as any,
                                startAdornment: "$",
                              }}
                              sx={{
                                "& .MuiInputBase-input": {
                                  paddingLeft: "10px",
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography
                              sx={{ color: neutral[500], mb: 0 }}
                              className="title"
                              variant="smallCopy"
                              paragraph
                            >
                              Available balance
                            </Typography>
                            <Typography
                              variant="bodyCopyBold"
                              sx={{ color: "neutral.main" }}
                            >
                              $
                              {moneyFormat(
                                selectedAccount?.availableBalance || 0
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box>
                        {checkBalance() && (
                          <FormHelperText>
                            {" "}
                            <ErrorIcon /> You are able to transfer only{" "}
                            {moneyFormat(
                              selectedAccount?.availableBalance || 0
                            )}
                          </FormHelperText>
                        )}
                      </Box>
                    </Stack>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Stack spacing={2}>
                    <Paper
                      variant="outlined"
                      sx={{ paddingY: 5, paddingX: 4, border: "none" }}
                    >
                      <Box>
                        <Typography
                          variant="subtitle1"
                          sx={{ color: "neutral.main" }}
                          gutterBottom
                        >
                          Summary
                        </Typography>
                        <Typography
                          variant="bodyCopyRegular"
                          sx={{ color: "neutral.main" }}
                        >
                          {selectedAccount?.fundAccountName}
                        </Typography>
                      </Box>
                      {!!requestedAmount && (
                        <>
                          <Box marginTop={3}>
                            <Typography
                              variant="smallCopy"
                              sx={{ color: "neutral.main", mb: 1 }}
                              className="title"
                              paragraph
                            >
                              Transferring to
                            </Typography>
                            <Typography
                              variant="bodyCopyRegular"
                              sx={{ color: "neutral.main", mb: 0 }}
                              paragraph
                            >
                              {selectedFund?.fundName}
                            </Typography>
                          </Box>
                          <Divider
                            sx={{
                              marginY: 2,
                              backgroundColor: warm[600],
                              borderWidth: "1px",
                            }}
                          />
                          <Box>
                            <Typography
                              variant="smallCopy"
                              sx={{ color: "neutral.main", mb: 1 }}
                              className="title"
                            >
                              Total amount
                            </Typography>
                            <Typography
                              sx={{ color: "primary.main" }}
                              variant="subtitle1"
                            >
                              ${moneyFormat(requestedAmount)}
                            </Typography>
                          </Box>
                        </>
                      )}
                    </Paper>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      disabled={
                        checkBalance() ||
                        !requestedAmount ||
                        isMakingTransferLoading
                      }
                      onClick={onConfirmTransfer}
                    >
                      Confirm
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      )}
    </Layout>
  );
};

/**
 *
 * TODO:// put those components on differet files
 */

const TransferCashTransactionPageStepper = ({ activeStep = 0 }) => {
  return (
    <Grid container sx={{ mb: 5, mt: 2 }}>
      <Grid item xs={12} sm={8} md={6}>
        <Stepper activeStep={activeStep}>
          <Step>
            <StepLabel>
              <Typography variant="smallCopy">STEP 1</Typography>
              <br />
              <Typography variant="subtitle3">Enter details</Typography>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
              <Typography variant="smallCopy">STEP 2</Typography>
              <br />
              <Typography variant="subtitle3">Request review</Typography>
            </StepLabel>
          </Step>
        </Stepper>
      </Grid>
    </Grid>
  );
};

export default CashTransferTransactionPage;
