export enum FUND_MOVEMENT_STATUS_ENUM {
  PENDING = "PENDING",
  REJECTED = "REJECTED",
  PROCESSED = "PROCESSED",
  APPROVED = "APPROVED",
  ACCEPTED = "ACCEPTED",
  CANCELED = "CANCELED",
}
export enum FUND_MOVEMENT_TYPE_ENUM {
  CONTRIBUTION = "CONTRIBUTION",
  INVESTMENT = "INVESTMENT",
  GRANT = "GRANT",
  GRANTVESTMENT = "GRANTVESTMENT",
  DISTRIBUTION = "DISTRIBUTION",
  FEE = "FEE",
  ADJUSTMENT = "ADJUSTMENT",
  TRANSFER = "TRANSFER",
}
export interface FundMovementHistory {
  fundId: number;
  type: FUND_MOVEMENT_TYPE_ENUM;
  date: Date;
  status: FUND_MOVEMENT_STATUS_ENUM;
  amount: number;
  userId: number;
  id: number;
  user: string;
  title: string;
  transactionType: string;
}
