import React from "react";
import { Box, Container, Grid } from "@mui/material";
import Layout from "components/layout/layout";
import { useParams } from "react-router-dom";

import { ReactComponent as InvestmentsIcon } from "./../../../assets/Icons/fluctuations.svg";
import { ReactComponent as ExtendedIcon } from "./../../../assets/Icons/extended.svg";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { TransactionDetailsCard } from "components/transaction-details/transaction-details";
import { useGetFundInvestmentRequestQuery } from "hooks/use-services/investment-request.service";
import { TransactionDetailsItem } from "components/transaction-details/transaction-details-item";
import {
  INVESTMENT_REQUEST_STATUS_ENUM,
  INVESTMENT_REQUEST_TYPE_ENUM,
} from "types/investment-request";
import { ReactComponent as TransferOutgoingIcon } from "./../../../assets/Icons/transfer-outgoing.svg";

import { ReactComponent as Dollar } from "./../../../assets/Icons/dollar.svg";

import { shortFormat } from "utils/date-format";
import { moneyFormat } from "utils/money-format";
import { primary } from "style/color";
import { BackToPreviousButton } from "components/shared/back-to-previous-button";
import { DIVIDEND_DISTRIBUTION_TYPE } from "types/fund-dividend-distribution";
export const InvestmentTransactionDetailsPage = () => {
  const { id } = useParams();
  const { data: investmentRequest, isSuccess } =
    useGetFundInvestmentRequestQuery(id ? +id : 0);
  const getStatusCard = () => {
    if (investmentRequest?.status === INVESTMENT_REQUEST_STATUS_ENUM.PENDING) {
      return {
        icon: <ExtendedIcon />,
        title: "Pending",
        text:
          investmentRequest?.type ===
            INVESTMENT_REQUEST_TYPE_ENUM.TRANSFER_SEND ||
          investmentRequest?.type ===
            INVESTMENT_REQUEST_TYPE_ENUM.TRANSFER_RECEIVE
            ? "Transactions appear as pending until transfer is received."
            : "Transactions appear as pending until the payment is received.",
      };
    }
    if (investmentRequest?.status === INVESTMENT_REQUEST_STATUS_ENUM.REJECTED) {
      return {
        icon: <HighlightOffIcon sx={{ color: primary[500] }} />,
        title: "Rejected",
        text: `Our team didn’t approve this transaction due to ${investmentRequest.rejectionReason}`,
      };
    }
    return undefined;
  };
  return (
    <Layout>
      {() => (
        <>
          <Box sx={{ my: 4, width: "100%" }}>
            <React.Fragment>
              <Container>
                {isSuccess && investmentRequest && (
                  <Grid sx={{ m: 5 }} container spacing={2}>
                    <Grid item xs={12} sm={9}>
                      <BackToPreviousButton />
                    </Grid>
                    {investmentRequest.type ===
                    INVESTMENT_REQUEST_TYPE_ENUM.DIVIDEND ? (
                      <Grid sx={{ m: 5 }} container spacing={2}>
                        <Grid item xs={12} sm={9}>
                          <TransactionDetailsCard
                            amount={investmentRequest.appliedUnits}
                            type="DIVIDENDS"
                            title={investmentRequest.investment.name}
                            avatar={<Dollar />}
                            status={"PROCESSED"}
                            dividendType={DIVIDEND_DISTRIBUTION_TYPE.UNIT}
                          >
                            <TransactionDetailsItem
                              label="Type"
                              value={`Distribution/Dividend`}
                            />
                            <TransactionDetailsItem
                              label="DATE"
                              value={
                                shortFormat(investmentRequest.executionDate)!
                              }
                            />
                            <TransactionDetailsItem
                              label="INITIATED BY"
                              value={`${investmentRequest.user.firstName} ${investmentRequest.user.lastName}`}
                            />
                            <TransactionDetailsItem
                              label="TICKER"
                              value={`${investmentRequest.investment.ticker}`}
                            />
                            <TransactionDetailsItem
                              label="Fund"
                              value={`${investmentRequest.fund.fundAccountName}`}
                            />
                          </TransactionDetailsCard>
                        </Grid>
                      </Grid>
                    ) : INVESTMENT_REQUEST_TYPE_ENUM.TRANSFER_RECEIVE ||
                      INVESTMENT_REQUEST_TYPE_ENUM.TRANSFER_SEND ? (
                      <Grid sx={{ m: 5 }} container spacing={2}>
                        <Grid item xs={12} sm={9}>
                          <TransactionDetailsCard
                            amount={
                              investmentRequest.type ===
                              INVESTMENT_REQUEST_TYPE_ENUM.TRANSFER_SEND
                                ? -investmentRequest.appliedUnits
                                : investmentRequest.appliedUnits
                            }
                            type={investmentRequest.type}
                            title={investmentRequest.investment.name}
                            avatar={<TransferOutgoingIcon />}
                            status={investmentRequest.status}
                            statusCard={getStatusCard()}
                          >
                            <TransactionDetailsItem
                              label="Type"
                              value={
                                investmentRequest.type ===
                                INVESTMENT_REQUEST_TYPE_ENUM.TRANSFER_SEND
                                  ? `Transfer Send`
                                  : "Transfer Receive"
                              }
                            />
                            <TransactionDetailsItem
                              label="DATE"
                              value={
                                shortFormat(investmentRequest.executionDate)!
                              }
                            />
                            <TransactionDetailsItem
                              label="INITIATED BY"
                              value={`${investmentRequest.user.firstName} ${investmentRequest.user.lastName}`}
                            />
                            <TransactionDetailsItem
                              label="TICKER"
                              value={`${investmentRequest.investment.ticker}`}
                            />
                            <TransactionDetailsItem
                              label="Fund"
                              value={`${investmentRequest.fund.fundAccountName}`}
                            />
                          </TransactionDetailsCard>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid item xs={12} sm={9}>
                        <TransactionDetailsCard
                          amount={investmentRequest.appliedAmount}
                          type="investment"
                          title={investmentRequest.investment.name}
                          avatar={<InvestmentsIcon />}
                          status={investmentRequest.status}
                          statusCard={getStatusCard()}
                        >
                          <TransactionDetailsItem
                            label="Type"
                            value={`Investment ${
                              investmentRequest.type ===
                              INVESTMENT_REQUEST_TYPE_ENUM.BUY
                                ? "Purchase"
                                : "Sale"
                            }`}
                          />
                          <TransactionDetailsItem
                            label="Date"
                            value={
                              (investmentRequest.status &&
                                (investmentRequest?.status as INVESTMENT_REQUEST_STATUS_ENUM)) ===
                              INVESTMENT_REQUEST_STATUS_ENUM.APPROVED
                                ? shortFormat(investmentRequest.executionDate)!
                                : shortFormat(investmentRequest.createdAt)!
                            }
                          />
                          <TransactionDetailsItem
                            label="Initiated by"
                            value={`${investmentRequest.user.firstName} ${investmentRequest.user.lastName}`}
                          />

                          <TransactionDetailsItem
                            label="Units"
                            value={moneyFormat(
                              investmentRequest.appliedUnits,
                              6
                            ).toString()}
                          />
                          <TransactionDetailsItem
                            label="Ticker"
                            value={investmentRequest.investment.ticker}
                          />
                          <TransactionDetailsItem
                            label="Fund"
                            value={investmentRequest.fund.fundAccountName}
                          />
                        </TransactionDetailsCard>
                      </Grid>
                    )}
                  </Grid>
                )}
              </Container>
            </React.Fragment>
          </Box>
        </>
      )}
    </Layout>
  );
};
