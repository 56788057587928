import React from "react";

import { createContext } from "react";

import { NetaSetting } from "constants/neta-settings";
import { useGetNetaSettingsQuery } from "hooks/use-services/neta-settings.service";

export const NetaSettingContext = createContext<NetaSetting>({
  name: "",
  ein: "",
  personContact: "",
  supportEmail: "",
  notificationEmail: "",
  ccEmail: "",
  addressLine1: "",
  addressLine2: "",
  receivingBankAccount: "",
  receivingBankAddressLine1: "",
  receivingBankAddressLine2: "",
  ABA: "",
  brokerName: "",
  DTCNumber: "",
  accountNumber: "",
  contributionMinimumAmount: 0,
  grantVestmentThreshold: 0,
  phoneNumber: "",
  mixpanelReplay: false,
  mixpanelSessionPercent: 0,
});

interface props {
  children?: React.ReactNode;
}
const NetaSettingContextProvider = ({ children }: props) => {
  const { data: netaSettingData } = useGetNetaSettingsQuery();

  return (
    <NetaSettingContext.Provider value={netaSettingData || {
      name: "",
      ein: "",
      personContact: "",
      supportEmail: "",
      notificationEmail: "",
      ccEmail: "",
      addressLine1: "",
      addressLine2: "",
      receivingBankAccount: "",
      receivingBankAddressLine1: "",
      receivingBankAddressLine2: "",
      ABA: "",
      brokerName: "",
      DTCNumber: "",
      accountNumber: "",
      contributionMinimumAmount: 0,
      grantVestmentThreshold: 0,
      phoneNumber: "",
      mixpanelReplay: false,
      mixpanelSessionPercent: 0,
    }}>
      {children}
    </NetaSettingContext.Provider>
  );
};

export default NetaSettingContextProvider;
