import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import "@aws-amplify/ui-react/styles.css";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import { CssBaseline } from "@mui/material";
import { theme } from "./style/theme";
import { Authenticator } from "@aws-amplify/ui-react";
import AppRouter from "./routing/Router";
import axios from "axios";
import UserContextProvider from "./providers/userProvider";
import { QueryClient, QueryClientProvider } from "react-query";
import { fetchAuthSession } from "aws-amplify/auth";
import NetaSettingContextProvider from "providers/netaSettingsProvider";

Amplify.configure(awsExports);

export const api = axios.create({
  baseURL: awsExports.endpoints[0].endpoint,
});

// TODO:: refactor axios client
api.interceptors.request.use(async (reqConfig) => {
  const { idToken } = (await fetchAuthSession()).tokens ?? {};
  reqConfig.headers.Authorization = `Bearer ${idToken?.toString() as string}`;
  return reqConfig;
});

const queryClient = new QueryClient({});

function App() {
  return (
    <div className="App">
      {/* Mui provider */}
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {/* Amplify provider */}
        <Authenticator.Provider>
          {/* Routing */}

          <BrowserRouter>
            <QueryClientProvider client={queryClient}>
              <NetaSettingContextProvider>
                <UserContextProvider api={api}>
                  <AppRouter />
                </UserContextProvider>
              </NetaSettingContextProvider>
            </QueryClientProvider>
          </BrowserRouter>
        </Authenticator.Provider>
      </ThemeProvider>
    </div>
  );
}
export default App;
