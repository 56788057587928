import {
  addTransferAPI,
  cancelTransferAPI,
  getTransferAPI,
  GetTransferQuery,
} from "api/transfer-transaction.api";
import { useMutation, useQuery } from "react-query";
import { PaginatedEntityList } from "types/pagination";
import {
  TransferTransaction,
  TransferTransactionInput,
} from "types/transfer-transaction";

const TRANSFER_RQ_KEY = "transfer-transactions";

export const useAddTransferMutation = (fundId: number) => {
  return useMutation<unknown, unknown, TransferTransactionInput>({
    mutationKey: [TRANSFER_RQ_KEY],
    mutationFn: (transfer: any) => addTransferAPI({ fundId, transfer }),
  });
};

export const useGetTransfers = (query: GetTransferQuery) => {
  return useQuery<PaginatedEntityList<TransferTransaction>>({
    queryKey: [TRANSFER_RQ_KEY],
    queryFn: () => getTransferAPI(query),
  });
};

export const useCancelTransferMutation = (transferId: number) => {
  return useMutation<unknown, unknown, number>({
    mutationKey: [TRANSFER_RQ_KEY, transferId],
    mutationFn: () => cancelTransferAPI(transferId),
  });
};
