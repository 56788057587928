import React, { FC, useContext } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";

import { ReactComponent as NotificationsIcon } from "./../../../assets/Icons/notification.svg";
import { ReactComponent as ThumbUpIcon } from "./../../../assets/Icons/thumb-up.svg";
import { ReactComponent as ThumbDownIcon } from "./../../../assets/Icons/thumb-down.svg";
import { ReactComponent as NoUpdatesIcon } from "./../../../assets/Icons/no-updates.svg";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import imagePlaceholader from "./../../../assets/image-placeholader.png";
import { GRANTVESTMENT_STATUS, GrantVestment } from "types/grant-vestment";
import { getPercentage, moneyFormat } from "utils/money-format";
import { longFormat, numberOfDays, shortFormat } from "utils/date-format";
import { useNavigate } from "react-router-dom";
import { USER_ROLE } from "types/account";
import { USER_STATUS } from "types/fund-account-invitation";
import { AuthContext } from "providers/userProvider";
import { AuthData } from "types/auth";

interface GrantVestmentsDetailsCardProps {
  grantvestment: GrantVestment;
  isAuthenticated: boolean;
  buttonLabel?: string;
  hideProgressBar?: boolean;
  handleOpen: (value: boolean) => void;
}

const GrantVestmentsDetailsCard: FC<GrantVestmentsDetailsCardProps> = ({
  grantvestment,
  isAuthenticated,
  buttonLabel,
  hideProgressBar,
  handleOpen,
}) => {
  const navigate = useNavigate();
  const { userData, userRole } = useContext<AuthData>(AuthContext);

  const funded = getPercentage(
    grantvestment?.raisedAmount,
    grantvestment?.goalAmount
  );
  const daysLeft =
    numberOfDays(grantvestment?.deadline) < 0
      ? 0
      : numberOfDays(grantvestment?.deadline);

  const grantVest = () => {
    if (isAuthenticated) {
      navigate(`/grant-vestments/${grantvestment?.grantVestmentId}/request`);
    } else {
      handleOpen(false);
    }
  };

  return (
    <Card
      className="single"
      sx={{
        backgroundColor: "transparent",
        mt: 5,
        display: { xs: "block", sm: "block", md: "flex" },
      }}
    >
      <CardMedia
        component="img"
        sx={{ width: { xs: "100%", md: "40%" }, height: "auto", flex: 2 }}
        image={
          grantvestment.imageUrl ? grantvestment.imageUrl : imagePlaceholader
        }
        alt="Live from space album cover"
      />
      {grantvestment?.status === GRANTVESTMENT_STATUS.CLOSED ? (
        <Box sx={{ ml: 2, my: 4, width: "100%", flex: 3 }}>
          <CardContent>
            <Typography
              variant="h3"
              sx={{ color: "neutral.main" }}
              gutterBottom
            >
              {grantvestment?.grantVestorCounter} GrantVestorsᵀᴹ backed this
              project with ${moneyFormat(grantvestment?.raisedAmount)}
            </Typography>
            {!hideProgressBar && (
              <Box sx={{ width: "100%", my: 2 }}>
                <LinearProgress
                  color="primary"
                  variant="determinate"
                  value={100}
                />
              </Box>
            )}

            <Typography
              variant="smallCopy"
              color={"primary"}
              className="title"
              paragraph
            >
              {funded}% funded
            </Typography>

            <Typography
              variant="bodyCopyBold"
              sx={{ color: "neutral.main" }}
              paragraph
            >
              Funds raised between {shortFormat(grantvestment?.launchedAt)} and{" "}
              {shortFormat(grantvestment?.closedAt)}
            </Typography>
          </CardContent>
          <CardActions disableSpacing>
            <Button
              startIcon={<NoUpdatesIcon />}
              variant="outlined"
              color="primary"
              size="large"
            >
              {" "}
              Stop receiving updates
            </Button>
          </CardActions>
        </Box>
      ) : (
        <Box sx={{ ml: 2, width: "100%", flex: 3 }}>
          <CardContent>
            {isAuthenticated ? (
              <>
                <Typography
                  variant="smallCopy"
                  className="title"
                  sx={{ color: "neutral.lighter" }}
                  paragraph
                >
                  Raised
                </Typography>
                <Typography
                  variant="h3"
                  sx={{ color: "neutral.main" }}
                  gutterBottom
                >
                  ${moneyFormat(grantvestment?.raisedAmount!)}
                  <Typography
                    variant="subtitle3"
                    sx={{
                      float: "right",
                      display: { xs: "none", md: "block" },
                    }}
                  >
                    ${moneyFormat(grantvestment?.goalAmount!)} goal
                  </Typography>
                </Typography>
                <Box sx={{ width: "100%", my: 2 }}>
                  <LinearProgress
                    color="primary"
                    variant="determinate"
                    value={parseFloat(funded) > 100 ? 100 : parseInt(funded)}
                  />
                </Box>
                <Typography
                  variant="smallCopy"
                  color={"primary"}
                  className="title"
                  paragraph
                >
                  {funded}% funded
                </Typography>
              </>
            ) : (
              <>
                <Typography
                  variant="subtitle3"
                  sx={{ color: "neutral.main" }}
                  paragraph
                >
                  Amount to be raised
                </Typography>
                <Typography
                  variant="h3"
                  sx={{ color: "neutral.main" }}
                  gutterBottom
                >
                  ${moneyFormat(grantvestment?.goalAmount!)}
                </Typography>
              </>
            )}

            <Stack direction="row" alignItems="center" gap={1} sx={{ mb: 1 }}>
              <AccessTimeIcon />
              <Typography variant="bodyCopyBold" sx={{ color: "neutral.main" }}>
                {daysLeft} days to go - until{" "}
                {shortFormat(grantvestment?.deadline)}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" gap={1} sx={{ mb: 1 }}>
              <PeopleOutlineIcon />
              <Typography variant="bodyCopyBold" sx={{ color: "neutral.main" }}>
                {grantvestment?.grantVestorCounter}
              </Typography>
            </Stack>
            {grantvestment?.allOrNothing && (
              <>
                <Typography
                  variant="smallCopyBold"
                  sx={{ color: "neutral.lighter", fontWeight: 700 }}
                >
                  All or nothing
                </Typography>
                <Typography
                  variant="smallCopy"
                  sx={{ color: "neutral.lighter" }}
                  paragraph
                >
                  This project only be funded if it reaches its goal by{" "}
                  {longFormat(grantvestment?.deadline)}
                </Typography>
              </>
            )}
          </CardContent>
          <CardActions disableSpacing>
            <Grid container rowSpacing={2} alignItems={"center"}>
              <Grid
                item
                xs={12}
                sm={7}
                md={7}
                sx={{ textAlign: { xs: "center", sm: "start" } }}
              >
                <Button
                  disabled={
                    daysLeft === 0 ||
                    userRole === USER_ROLE.VIEWER ||
                    userData?.user?.status === USER_STATUS.PENDING
                  }
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={grantVest}
                >
                  {buttonLabel ? buttonLabel : "Recommend a GrantVestment"}
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                sm={5}
                md={5}
                sx={{ textAlign: { xs: "center", sm: "end" } }}
              >
                <IconButton className="grantvenstment">
                  {" "}
                  <NotificationsIcon />
                </IconButton>
                <IconButton className="grantvenstment">
                  {" "}
                  <ThumbUpIcon />{" "}
                </IconButton>
                <IconButton className="grantvenstment" color="secondary">
                  {" "}
                  <ThumbDownIcon />
                </IconButton>
              </Grid>
            </Grid>
          </CardActions>
        </Box>
      )}
    </Card>
  );
};

export default GrantVestmentsDetailsCard;
